<!-- 
    <componentTooltip :text="'Mon message'" :position="'right'">
        <div class="test">
            <p>je suis la pour un simple test</p><br>
        </div>
        <button>test moi !</button>
    </componentTooltip> 

    position : Position de la Tooltip
        Default value : top
        Other value : left, right or bottom

    text : contenue de la Tooltip
        value : custom
-->

<script>
//import store from './../../../store/index.js';

export default {
    props: {
        text: String,
        position: String,
        // key: Number,
        active: {
            default: true,
            type: Boolean
        },

        condition: {
            type: Function,
            //params => (divHasTooltip)
            default() {
                return true
            }
            
        }
    },

    computed: {
        tooltipDisplay() {
            return true
            //return this.$store.getters.tooltipDisplay;
        }
    },

    methods: {
        showToolTip(event) {
            console.log(event)
            if(this.active && this.condition(this.$refs.divHasTooltip)) {
                // this.$store.commit('updateTooltipContent', this.text);
                // this.$store.commit('updateTooltipPosition', this.position || "");
                // this.$store.commit('updateTooltipElementTarget', event.currentTarget);
                // this.$store.commit('updateTooltipDisplay', true);
            }
        },
        hideToolTip() {
            //this.$store.commit('updateTooltipDisplay', false);
        },
    },

    beforeDestroy() {
        this.hideToolTip();
    },
}

</script>

<template>
    <div ref="divHasTooltip" class="hasTooltip" @mouseover="showToolTip($event)" @mouseleave="hideToolTip">
        <slot></slot>
    </div>
</template>

