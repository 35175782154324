<template>
    <div  :class="{switch:true, questionActive: questionIsActive, ['size-'+size] : true}">
        <input type="checkbox" :checked="value" @change="(e) => onChange(e.target.checked,id)">
        <div class="border">
            <span class="containerCircle"><span class="circle"></span></span>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'component-switch',

    props : {
        questionIsActive : {
            type: Boolean,
            default : false
        },
        size : {
            type: String,
            default : 's'
        },
        value : {
            type: Boolean,
            default : false
        },

        id: {
            type: String,
            default : 'null'
        },

        onChange : {
            type: Function,
            default(){

            }
        },
    },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>