<template>
    <div :class="{inputRange: true, countPopUpActive: popUpIsActive}">
        <input  type="range" :min="valueMin" :max="valueMax" @input="onChange" :value="value">
        <div class="countPopUp" :style="{ left: value/(valueMax - valueMin)*100*0.974 + '%' }">
            {{ value }}
        </div>
        <div class="progression" :style="{ width: value/(valueMax - valueMin)*100 + '%' }"></div>
       
    </div>
</template>

<script>



export default {
    name: 'inputRange',

    props: {
        valueMax : {
            type: Number,
            default : 100
        },

        valueMin : {
            type: Number,
            default : 0
        },
        
        value : {
            type: Number,
            default : 10
        },

        popUpIsActive : {
            type: Boolean,
            default : false
        },

        onChange : {
            type: Function,
            default(){

            }
        },

    },

    data() {
        return {
         
        };
    },

    mounted() {
        
    },


    methods: {
    },
};
</script>

<style lang="scss" scoped>

</style>