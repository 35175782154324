<template>
  <div class="plan">
      <div class="container-title">
          <div class="left">
              <h2 class="title-section-plan">{{ $t('dash.plan.simulation-title') }}</h2>
              <p>{{ $t('dash.plan.simulation_subtitle') }}</p>
          </div>
          <div class="right">
              <!-- <div class="btn-primary" @click="beContacted">{{ $t('dash.plan.contact_me') }}</div> -->
          </div>
          
      </div>
      
      <div class="content-plan">
          <div class="form">
              <div class="card-cursor">
                  <div class="icon">
                      <i class="fa-solid fa-earth-americas"></i>
                  </div>
                  <div class="content-card-cursor">
                      <div class="top">
                          <div class="text">
                              <h5>{{ $t('dash.plan.card.pages_number.title') }}</h5>
                              <p>{{ $t('dash.plan.card.pages_number.subtitle') }}</p>
                          </div>
                          <div class="input">
                              <input @input="(e)=>handleInputChange(e.target.value,'Network')" @blur="(e)=>handleInputBlur(e.target.value,'Network')"  :value="cursorNetwork.value">
                          </div>
                      </div>
                      
                      <div class="bottom">
                          <InputRange :popUpIsActive="true" :value="cursorNetwork.value" :valueMin="0" :valueMax="cursorNetwork.valueMax" :onChange="handleChangeAmountNetworkByInputRange"></InputRange>
                      </div>

                     
                  </div>
              </div>

              <div class="card-cursor">
                  <div class="icon">
                      <i class="fa-solid fa-user-group" style="font-size: 18px;"></i>
                  </div>
                  <div class="content-card-cursor">
                      <div class="top">
                          <div class="text">
                              <h5>{{ $t('dash.plan.card.users_number.title') }}</h5>
                              <p>{{ $t('dash.plan.card.users_number.subtitle') }}</p>
                              
                          </div>
                          <div class="input">
                              <input @input="(e)=>handleInputChange(e.target.value,'User')" @blur="(e)=>handleInputBlur(e.target.value,'User')"  :value="cursorUser.value">
                          </div>
                      </div>
                      
                      <div class="bottom">
                          <InputRange :popUpIsActive="true" :value="cursorUser.value" :valueMin="0" :valueMax="cursorUser.valueMax" :onChange="handleChangeAmountUserByInputRange"></InputRange>
                      </div>

                     
                  </div>
              </div>

              <div class="card-cursor">
                  <div class="icon">
                      <i class="fa-solid fa-rss" style="font-size: 18px;"></i>
                  </div>
                  <div class="content-card-cursor">
                      <div class="top">
                          <div class="text">
                              <h5>{{ $t('dash.plan.card.source_number.title') }}</h5>
                              <p>{{ $t('dash.plan.card.source_number.subtitle') }}</p>
                              
                          </div>
                          <div class="input">
                              <input @input="(e)=>handleInputChange(e.target.value,'Source')" @blur="(e)=>handleInputBlur(e.target.value,'Source')"  :value="cursorSource.value">
                          </div>
                      </div>
                      
                      <div class="bottom">
                          <InputRange :popUpIsActive="true" :value="cursorSource.value" :valueMin="0" :valueMax="cursorSource.valueMax" :onChange="handleChangeAmountSourceByInputRange"></InputRange>
                      </div>

                     
                  </div>
              </div>
              
              <div class="card-question">
                  <div class="icon">
                      <i class="fa-solid fa-clipboard-question" style="font-size: 22px;"></i>
                  </div>
                  <div class="content-card-question">
                      <div class="top">
                          <div class="text">
                              <h5>{{ $t('dash.plan.card.questions.title') }}</h5>
                          </div>
                      </div>
                      
                      <div class="bottom">
                          <div class="question" v-for="question in questions" :key="question.id">
                              <div class="text">
                                  {{ $t(question.text) }}
                                  <span v-if="question.info" class="iconInfo">
                                      <Tooltip :text="$t(question.info)">
                                          <i class="fa-regular fa-circle-question"></i>
                                      </Tooltip>
                                  </span>
                                  <div v-if="question.badge" class="badge-outline-r">{{ $t(question.badge) }}</div>
                              </div>
                              <input v-if="question.typeResponse == 'inputNumber' || question.typeResponse == 'inputString'" @input="(e)=>handleValueQuestionChange(e.target.value,question.id)" :value="question.value" maxlength="3">
                              <ComponentSwitch v-if="question.typeResponse == 'switch'" :value="question.value" :questionIsActive="true" :onChange="handleValueQuestionChange" :id="question.id"></ComponentSwitch>
                          </div>
                      </div>      
                  </div>
              </div>
              <p v-if="currentPlan" class="cancel-plan cancel-plan-1" @click.stop="cancelSubscriptionModal">{{ $t('dash.plan.cancel_subscription') }}</p>
          </div>

          <div class="render">
              <div class="box-plan">
                  <h4>{{$t('dash.plan.your_subscription')}}</h4>

                  <div class="info-plan">
                      <div class="container-price">
                          <div :class="{price : true, ['length'+totalPrice.toString().length] : true}">
                              
                              <span class="totalPrice">{{totalPrice}}€</span>
                              <span class="frequencyPlan" v-if="frequencyPlan == 'month'"> /{{ $t('dash.plan.month') }}</span>
                              <span class="frequencyPlan" v-else> /{{ $t('dash.plan.year') }}</span>
                              <div v-if="frequencyPlan == 'year'" class="badge-outline-r">{{$t('dash.plan.economy').replace('%',textEconomy)}}</div>
                          </div>
                          <div class="frequency">
                              <p :class="{active : frequencyPlan == 'month'}">{{ $t('dash.plan.monthly') }}</p> 
                              <ComponentSwitch :value="frequencyPlan == 'year'" :onChange="(value)=>{frequencyPlan=value ? 'year' : 'month';generatePriceAndFonctionnality()}"></ComponentSwitch>
                              <p :class="{active : frequencyPlan == 'year'}">{{ $t('dash.plan.annual') }}</p>
                          </div>
                      </div>
                      
                      <div class="fonctionnality">
                          <h5>{{ $t('dash.plan.fonctionnalitys') }}</h5>
                          <div class="list-fonctionnality">
                              <div class="item">
                                  <i class="fa-solid fa-check"></i>
                                  {{ cursorNetwork.value }} {{  $t('dash.plan.fonctionnalitys.connect_pages_number') }}
                              </div>

                              <div class="item">
                                  <i class="fa-solid fa-check"></i>
                                  {{ cursorUser.value }} {{  $t('dash.plan.fonctionnalitys.connect_users_number') }}
                              </div>

                              <div class="item" v-for="(text, index) in fonctionnality" :key="index">
                                  <i class="fa-solid fa-check"></i>
                                  {{ $t(text) }}
                              </div>

                          </div>
                      </div>
                  </div>
                  <div class="btn-secondary disabled" v-if="loaders.subscription">
                      <div class="util-loader"></div>
                  </div>
                  <div class="btn-secondary disabled" v-else-if="isCurrentPlan">
                      {{ $t('dash.settings.plans.backtofree') }}
                  </div>
                  <div v-else class="btn-secondary" @click.stop="startSubscription" >
                      {{ $t('dash.settings.plans.backtofree') }}
                  </div>

                  <div class="svg-right">
                      <svg  viewBox="0 0 125 366" height="100%" width="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M46.9257 0.413976C46.9257 0.413976 31.2773 2.18206 30.268 26.4042C29.4256 46.5893 27.7105 57.8428 35.9217 62.0544C38.8586 63.5569 42.3116 63.6935 45.4458 62.6614L78.9737 51.6355C78.9737 51.6355 79.4822 15.2948 67.8711 6.46951C56.26 -2.36337 46.9257 0.413976 46.9257 0.413976Z" fill="#2F2E41"/>
                          <path d="M65.4122 56.0975L48.4434 61.675V37.2556H63.8337L65.4122 56.0975Z" fill="#FFB6B6"/>
                          <path d="M13.5422 194.745C12.6239 200.337 8.89015 204.352 5.20952 203.707C1.52889 203.062 -0.717428 198 0.208422 192.4C0.542335 190.161 1.43022 188.037 2.79623 186.223L7.0005 162.57L18.4446 164.968L13.0792 188.029C13.785 190.215 13.9444 192.514 13.5422 194.745Z" fill="#FFB6B6"/>
                          <path d="M106.043 192.028C107.174 197.583 105.102 202.66 101.429 203.365C97.7564 204.071 93.8632 200.133 92.7401 194.578C92.262 192.362 92.3378 190.07 92.9601 187.885L88.4902 164.278L100.041 162.449L103.22 185.912C104.655 187.703 105.618 189.797 106.043 192.028Z" fill="#FFB6B6"/>
                          <path d="M59.0299 329.499H43.1387V352.044H59.0299V329.499Z" fill="#FFB6B6"/>
                          <path d="M27.6654 365.696C25.9958 365.696 24.5083 365.658 23.3852 365.552C19.1657 365.165 15.136 362.046 13.1098 360.225C12.1991 359.405 11.9107 358.1 12.3812 356.977C12.7227 356.172 13.3982 355.565 14.2405 355.33L25.3962 352.143L43.4579 339.956L43.6628 340.32C43.7387 340.457 45.5145 343.652 46.1064 345.807C46.3341 346.626 46.2734 347.309 45.9319 347.84C45.6967 348.205 45.3627 348.417 45.0971 348.539C45.4234 348.88 46.4479 349.578 49.6049 350.079C54.2114 350.807 55.1828 346.034 55.2208 345.837L55.2511 345.678L55.3877 345.587C57.5809 344.175 58.9317 343.53 59.3947 343.667C59.683 343.75 60.1687 343.902 61.4816 356.901C61.6106 357.311 62.5289 360.301 61.9066 363.161C61.2312 366.273 47.6318 365.203 44.9074 364.96C44.8315 364.967 34.6472 365.696 27.673 365.696H27.6654Z" fill="#2F2E41"/>
                          <path d="M104.277 311.79L90.793 320.199L102.724 339.328L116.208 330.92L104.277 311.79Z" fill="#FFB6B6"/>
                          <path d="M86.3729 362.607C84.506 362.607 82.7909 362.38 81.5691 362.167C80.37 361.955 79.429 360.999 79.2317 359.8C79.0951 358.935 79.3456 358.062 79.9375 357.417L87.7161 348.812L96.5952 328.915L96.9595 329.112C97.0961 329.188 100.291 330.956 101.938 332.474C102.568 333.051 102.879 333.665 102.864 334.295C102.856 334.735 102.689 335.084 102.522 335.335C102.977 335.456 104.214 335.502 107.159 334.257C111.454 332.444 109.754 327.875 109.679 327.686L109.618 327.534L109.686 327.39C110.802 325.03 111.598 323.77 112.069 323.641C112.365 323.558 112.851 323.429 120.842 333.772C121.168 334.045 123.528 336.101 124.515 338.856C125.585 341.853 113.481 348.144 111.045 349.373C110.969 349.434 98.3027 358.639 93.0967 361.257C91.0325 362.296 88.5889 362.615 86.3653 362.615L86.3729 362.607Z" fill="#2F2E41"/>
                          <path d="M70.7324 140.966H26.2385L22.2012 182.353L39.8682 335.274H62.5819L53.4979 246.953L90.3422 326.692L110.529 312.562L81.7591 238.12C81.7591 238.12 92.0345 173.27 83.9599 157.114C75.8853 140.966 70.7324 140.966 70.7324 140.966Z" fill="#2F2E41"/>
                          <path d="M92.8619 143.493H15.6445L43.4048 52.144H74.1931L92.8619 143.493Z" fill="#AB6E93"/>
                          <path d="M48.2618 52.1592C48.2618 52.1592 29.0845 51.6507 27.0659 56.7046C25.0472 61.7509 1.32422 181.867 1.32422 181.867H16.9726L48.2693 52.1592H48.2618Z" fill="#AB6E93"/>
                          <path d="M60.7383 52.2654C60.7383 52.2654 77.6312 50.1254 79.6499 55.1717C81.6685 60.218 105.392 180.334 105.392 180.334H89.7432L60.7383 52.2654Z" fill="#AB6E93"/>
                          <path d="M50.5468 44.0168C59.8849 44.0168 67.4549 36.4473 67.4549 27.1099C67.4549 17.7725 59.8849 10.203 50.5468 10.203C41.2087 10.203 33.6387 17.7725 33.6387 27.1099C33.6387 36.4473 41.2087 44.0168 50.5468 44.0168Z" fill="#FFB6B6"/>
                          <path d="M45.9175 6.71994C45.9175 6.71994 36.6134 44.5025 46.9572 62.1606L32.3864 59.0646C32.3864 59.0646 26.7402 17.7155 38.0933 10.1954L45.9175 6.71234V6.71994Z" fill="#2F2E41"/>
                      </svg>
                  </div>

                  <div class="svg-left">
                      <svg height="100%" width="100%" viewBox="0 0 89 425" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M72.9557 7.32775C75.0426 2.05382 79.5429 -1.07259 83.0034 0.33885C86.464 1.75029 87.5795 7.17598 85.4926 12.4499C84.6882 14.5671 83.3753 16.449 81.6526 17.9363L72.5155 40.1551L61.8379 35.382L71.9767 13.9828C71.749 11.6987 72.0829 9.41455 72.9481 7.32016L72.9557 7.32775Z" fill="#A0616A"/>
                          <path d="M34.6698 149.177C34.6698 149.177 19.3478 141.756 20.4102 136.429C21.4727 131.094 67.1201 17.4885 67.1201 17.4885L88.2553 31.3753L34.6698 149.185V149.177Z" fill="#E6E6E6"/>
                          <path d="M21.1309 97.508L40.5661 103.897V75.9191H22.9294L21.1309 97.508Z" fill="#A0616A"/>
                          <path d="M38.1619 83.6592C48.8579 83.6592 57.5288 74.989 57.5288 64.2937C57.5288 53.5984 48.8579 44.9282 38.1619 44.9282C27.4658 44.9282 18.7949 53.5984 18.7949 64.2937C18.7949 74.989 27.4658 83.6592 38.1619 83.6592Z" fill="#A0616A"/>
                          <path d="M33.2291 65.8114L35.9004 69.0516L40.7269 60.5982C40.7269 60.5982 46.8891 60.9169 46.8891 56.3411C46.8891 51.7653 52.5429 51.6363 52.5429 51.6363C52.5429 51.6363 60.5492 37.6585 43.9674 41.3388C43.9674 41.3388 32.4626 33.4621 26.7481 40.193C26.7481 40.193 9.21011 49.0259 14.2264 64.3999L22.5666 80.2521L24.4563 76.6627C24.4563 76.6627 23.3103 61.5998 33.2291 65.7962V65.8114Z" fill="#2F2E41"/>
                          <path d="M15.2963 410.43H31.1875L31.1875 387.885H15.2963L15.2963 410.43Z" fill="#A0616A"/>
                          <path d="M29.4342 423.346C26.7173 423.588 13.118 424.666 12.435 421.547C11.8127 418.686 12.7309 415.696 12.86 415.287C14.1653 402.28 14.6509 402.136 14.9469 402.053C15.4098 401.916 16.7607 402.561 18.9539 403.972L19.0905 404.063L19.1208 404.223C19.1588 404.428 20.1302 409.201 24.7366 408.465C27.8936 407.964 28.9181 407.266 29.2445 406.924C28.9788 406.803 28.6449 406.59 28.4097 406.226C28.0682 405.695 28.0075 405.012 28.2351 404.192C28.8271 402.03 30.6029 398.835 30.6788 398.706L30.8837 398.342L48.9453 410.529L60.101 413.716C60.9434 413.959 61.6188 414.558 61.9603 415.363C62.4308 416.486 62.1425 417.791 61.2318 418.61C59.2056 420.432 55.1834 423.55 50.9564 423.937C49.8332 424.044 48.3458 424.082 46.6762 424.082C39.702 424.082 29.5177 423.361 29.4418 423.346H29.4342Z" fill="#2F2E41"/>
                          <path d="M51.488 193.516L9.80197 191.384C9.80197 191.384 3.41209 215.606 11.4867 240.845L12.4581 392.833H36.0141L58.2193 236.049L51.488 193.516Z" fill="#2F2E41"/>
                          <path d="M44.4529 92.1354L21.5722 79.994C21.5722 79.994 -3.0842 116.024 0.323228 135.541C3.73825 155.058 9.79422 191.391 9.79422 191.391L60.2682 193.524L51.4574 121.973L44.4529 92.1354Z" fill="#E6E6E6"/>
                          <path d="M26.6029 410.43H42.4941V387.885H26.6029L26.6029 410.43Z" fill="#A0616A"/>
                          <path d="M40.7408 423.346C38.024 423.588 24.4246 424.666 23.7416 421.547C23.1193 418.686 24.0376 415.696 24.1666 415.287C25.4719 402.28 25.9576 402.136 26.2536 402.053C26.7165 401.916 28.0673 402.561 30.2605 403.972L30.3971 404.063L30.4275 404.223C30.4654 404.428 31.4368 409.201 36.0433 408.465C39.2003 407.964 40.2248 407.266 40.5511 406.924C40.2855 406.803 39.9516 406.59 39.7163 406.226C39.3748 405.695 39.3141 405.012 39.5418 404.192C40.1337 402.03 41.9095 398.835 41.9854 398.706L42.1903 398.342L60.252 410.529L71.4077 413.716C72.2501 413.959 72.9255 414.558 73.267 415.363C73.7375 416.486 73.4491 417.791 72.5384 418.61C70.5122 420.432 66.4901 423.55 62.263 423.937C61.1399 424.044 59.6524 424.082 57.9829 424.082C51.0086 424.082 40.8243 423.361 40.7484 423.346H40.7408Z" fill="#2F2E41"/>
                          <path d="M60.2681 193.516L9.79416 191.384C9.79416 191.384 3.40428 215.606 11.4789 240.845L21.2383 392.833H44.7943L66.9995 236.049L60.2681 193.516Z" fill="#2F2E41"/>
                          <path d="M79.6584 233.203C81.5177 238.561 80.1441 243.865 76.6001 245.056C73.0561 246.24 68.6773 242.863 66.818 237.498C66.0439 235.366 65.8162 233.082 66.1501 230.836L58.584 208.033L69.7853 204.686L76.0613 227.52C77.7233 229.106 78.9603 231.056 79.666 233.203H79.6584Z" fill="#A0616A"/>
                          <path d="M16.1543 100.71C16.1543 100.71 32.6071 96.3394 35.286 101.075C37.9573 105.81 77.4577 221.699 77.4577 221.699L52.3839 224.962L16.1619 100.71H16.1543Z" fill="#E6E6E6"/>
                      </svg>
                  </div>
                  

              </div>
          </div>
          <p v-if="currentPlan" class="cancel-plan cancel-plan-2" @click.stop="cancelSubscriptionModal">{{ $t('dash.plan.cancel_subscription') }}</p>
      </div>
      
  </div>
</template>


<script>
import InputRange from './inputRange.vue';
import ComponentSwitch from './switch.vue';
import Tooltip from './tooltipInit.vue';
import locales from '../locales/index.js';
// import subscriptionMixins from '../../mixins/Subscriptions.vue';
// import paymentMethodMixins from '../../mixins/PaymentMethod.vue';
// import SubscriptionsMixins from './../../mixins/Subscriptions.vue'

// import { EventBus } from '@/event-bus';

export default {
  name: 'compoenent-plan',

  components : {
      InputRange,
      ComponentSwitch,
      Tooltip
  },

  //mixins: [subscriptionMixins,paymentMethodMixins,SubscriptionsMixins],

  props: {
      userProp: {
          type: Object,
          default: () => {}
      },
      account: {
          type: Object,
          default: () => {}
      },
  },
  
  data() {
      return {
          questions : [
             {
                  id : '2',
                  text : 'dash.plan.card.questions.question2',
                  typeResponse : 'switch',
                  value : true, 
                  price : 10,
                  fonctionnality :  'Planification des publications',
             },
             {
                  id : '3',
                  text : 'dash.plan.card.questions.question3',
                  typeResponse : 'switch',
                  value : true, 
                  price : 10,
                  fonctionnality :  'Reporting des activités',
             },
             {
                  id : '4',
                  text : 'dash.plan.card.questions.question4',
                  typeResponse : 'switch',
                  value : true, 
                  price : 10,
                  fonctionnality :  'Modération des réseaux',
             },
             {
                  id : '5',
                  text : 'dash.plan.card.questions.question5',
                  typeResponse : 'switch',
                  badge: 'dash.plan.exclusife',
                  value : true, 
                  price : 10,
                  fonctionnality :  'Créeation et planification des stories Instagram',
             },
             {
                  id : '6',
                  text : 'dash.plan.card.questions.question6',
                  typeResponse : 'switch',
                  badge: 'dash.plan.exclusife',
                  value : true, 
                  price : 10,
                  fonctionnality :  'Tague/mention des personnes sur Linkedin',
             }
          ],

          cursorNetwork : {
              value : 10,
              valueMax : 250,
              price : 2
          },

          cursorUser : {
              value: 5,
              valueMax : 200,
              price : 2
          },

          cursorSource : {
              value: 10,
              valueMax : 250,
              price : 2
          },
        
          plan : {
              "starter" : {

                  "price" : 20,

                  "condition" : {
                      "user" : (amount)=> amount <= 5,
                      "network" : (amount)=> amount <= 10,
                      "sources" : (amount)=> amount <= 10,
                      "maxUser" : 5,
                      "maxNetwork" : 10,
                      "maxSources" : 10,
                  },

                  "fonctionnality" : [
                      "dash.plan.fonctionnalitys.post_source"
                  ],

                  "option" : {
                      "conciergerie" : 100,
                      "reporting" : 50
                  }
                  
              },

              "standard" : {
                  "price" : 120,

                  "condition" : {
                      "user" : (amount)=> amount <= 50,
                      "network" : (amount)=> amount <= 60,
                      "sources" : (amount)=> amount <= 60,
                      "maxUser" : 50,
                      "maxNetwork" : 60,
                      "maxSources" : 60,
                  },
                  "fonctionnality" : [
                      "dash.plan.fonctionnalitys.post_source"
                  ],

                  "option" : {
                      "conciergerie" : 150,
                      "reporting" : 0
                  }
              },

              "premium" : {
                  "price" : 600,

                  "condition" : {
                      "user" : (amount)=> amount <= 200,
                      "network" : (amount)=> amount <= 250,
                      "sources" : (amount)=> amount <= 250,
                      "maxUser" : 200,
                      "maxNetwork" : 250,
                      "maxSources" : 250,
                  },

                  "fonctionnality" : [
                      "dash.plan.fonctionnalitys.post_source"
                  ],

                  "option" : {
                      "conciergerie" : 0,
                      "reporting" : 0
                  }
              },
          },

          //box plan
          frequencyPlan : 'year',
          textEconomy : '',
          planSelect : '',
          optionsChoice : [],
          currentPlan : null,
          totalPrice : 0,
          fonctionnality : [],
          user : {},
          loaders : {
              subscription : false
          }
      };
  },

  mounted() {
    console.log("locales",locales)
    //   EventBus.$on('handlerCancelSubscription', this.cancelSubscription);
    //   EventBus.$on('handlerEditPaymentMethod', this.handlerEditPaymentMethod);
    //   EventBus.$on('handlerSubscribeFinish', this.handlerSubscribeFinish);
    //   EventBus.$on('handlerContactStaff', this.beContacted);

      this.user = JSON.parse('{"_premium":{"active":false,"trial":"1970-01-01T00:00:00.000Z"},"id":"a0d6c9c7-a2f7-4973-b975-3b8a8274d93b","account":"526ce51c-664b-460c-ac4e-096ef9769601","firstname":"Clement","lastname":"Guilloux","phone":"0621721156","avatar":"https://s3-eu-west-1.amazonaws.com/saas.mashup/uploads/0iel1dsi7pco-0k0oebpidbwd-facebook-108113978300524.png","email":"clement@mashup-web.com","admin":true,"created_at":"2024-01-12T09:24:40.000Z","updated_at":"2024-02-07T11:58:08.000Z","confirmed_at":"2024-01-12T09:25:33.000Z","logged_at":"2024-02-07T11:57:51.000Z","partner":false,"pages_access":[],"role":[],"notifications":null}');
      this.initPlan() 
      this.generatePriceAndFonctionnality()
  },

  computed: {
      isCurrentPlan() {
          return false
      }
  },


  methods: {
        $t(text){
            if (locales["fr"][text] ) {
                return locales["fr"][text] 
            }
            return text 
        },
        reset() {
            this.questions[0].value = true
            this.questions[1].value = true
            this.questions[2].value = true
            this.questions[3].value = true
            this.questions[4].value = true
            this.currentPlan = null
            this.cursorNetwork.value = 10
            this.cursorUser.value = 5
            this.cursorSource.value  = 10
            this.frequencyPlan = 'year'
        },
        initPlan(user) {
            //permet de mettre les valeur du plan actuel
            user = JSON.parse('{"_premium":{"active":false,"trial":"1970-01-01T00:00:00.000Z"},"id":"a0d6c9c7-a2f7-4973-b975-3b8a8274d93b","account":"526ce51c-664b-460c-ac4e-096ef9769601","firstname":"Clement","lastname":"Guilloux","phone":"0621721156","avatar":"https://s3-eu-west-1.amazonaws.com/saas.mashup/uploads/0iel1dsi7pco-0k0oebpidbwd-facebook-108113978300524.png","email":"clement@mashup-web.com","admin":true,"created_at":"2024-01-12T09:24:40.000Z","updated_at":"2024-02-07T11:58:08.000Z","confirmed_at":"2024-01-12T09:25:33.000Z","logged_at":"2024-02-07T11:57:51.000Z","partner":false,"pages_access":[],"role":[],"notifications":null}');
            console.log("initPlan" , user)

            if (user && user._premium && user._premium.active && user._premium.plan > 0) {  
                this.fonctionnality = [];
                this.currentPlan = Object.keys(this.plan)[user._premium.plan - 1];
                this.cursorNetwork.value = this.plan[this.currentPlan].condition.maxNetwork;
                this.cursorUser.value = this.plan[this.currentPlan].condition.maxUser;
                this.cursorSource.value  = this.plan[this.currentPlan].condition.maxSources;

                let options_plan= user._premium.options_plan && user._premium.options_plan.length ? user._premium.options_plan : [];
            
                if (!options_plan.includes("reporting") && this.plan[this.currentPlan].option.reporting > 0) {
                    this.questions[0].value = false
                    this.questions[1].value = false
                    this.questions[2].value = false
                    this.optionsChoice.push("reporting")
                }
                if (!options_plan.includes("conciergerie") && this.plan[this.currentPlan].option.conciergerie > 0) {
                    this.questions[3].value = false
                    this.questions[4].value = false
                    this.optionsChoice.push("conciergerie")
                }

                this.frequencyPlan = user._premium.is_annual ? 'year' : 'month'
                
                
            }
        },
        generatePriceAndFonctionnality(){
            this.fonctionnality = [];
            this.optionsChoice = [];
            for (const [namePlan, configPlan] of Object.entries(this.plan)) {
                if(configPlan.condition.user(this.cursorUser.value) && configPlan.condition.network(this.cursorNetwork.value) && configPlan.condition.sources(this.cursorSource.value)) {

                    let totalPrice = configPlan.price;
                    const conciergerieSelected = this.questions.some(q => ['5', '6'].includes(q.id) && q.value);
                    const reportingSelected = this.questions.some(q => ['2', '3', '4', '5', '6'].includes(q.id) && q.value);

                    if (conciergerieSelected || configPlan.option.conciergerie == 0){
                        totalPrice += configPlan.option.conciergerie;
                        this.fonctionnality.push("dash.plan.fonctionnalitys.concierge");
                        this.optionsChoice.push("conciergerie")
                    } 
                    if (reportingSelected || configPlan.option.reporting == 0){
                        totalPrice += configPlan.option.reporting;
                        this.fonctionnality.push("dash.plan.fonctionnalitys.planning");
                        this.fonctionnality.push("dash.plan.fonctionnalitys.moderation");
                        this.fonctionnality.push("dash.plan.fonctionnalitys.reporting");
                        this.optionsChoice.push("reporting")
                        
                    } 
                    this.fonctionnality = [...configPlan.fonctionnality,...this.fonctionnality];

                    this.textEconomy = '';
                    if(this.frequencyPlan === 'year') {
                        this.textEconomy = Math.floor(totalPrice*2);
                        totalPrice *=  10
                    }
                    this.totalPrice =  Math.floor(totalPrice);
                    this.planSelect = namePlan;
                    return
                }
            }
            
            this.totalPrice = '?'
        },

        getInfoPlanSelectForCrisp(){
            for (const [namePlan, configPlan] of Object.entries(this.plan)) {
                if(configPlan.condition.user(this.cursorUser.value) && configPlan.condition.network(this.cursorNetwork.value) && configPlan.condition.sources(this.cursorSource.value)) {   
                    let data =  {
                        'namePlan' : namePlan,
                        'options' : []
                    }

                    if (configPlan.option.conciergerie > 0 && this.fonctionnality.includes("dash.plan.fonctionnalitys.concierge")){
                        data.options.push("dash.plan.fonctionnalitys.concierge");
                    }
                    if (configPlan.option.reporting > 0 && this.fonctionnality.includes("dash.plan.fonctionnalitys.reporting")){
                        data.options.push("dash.plan.fonctionnalitys.planning");
                        data.options.push("dash.plan.fonctionnalitys.moderation");
                        data.options.push("dash.plan.fonctionnalitys.reporting");
                    } 

                    return data;
                }
            }
        },

        handleChangeAmountNetworkByInputRange(e) {
            this.cursorNetwork.value = parseInt(e.target.value)
            this.generatePriceAndFonctionnality()
        },

        handleChangeAmountUserByInputRange(e) {
            this.cursorUser.value = parseInt(e.target.value)
            this.generatePriceAndFonctionnality()
        },

        handleChangeAmountSourceByInputRange(e) {
            this.cursorSource.value = parseInt(e.target.value)
            this.generatePriceAndFonctionnality()
        },


        handleInputChange(value, property) {   //Property = User or Network or Source
            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                this['cursor'+property].value = ''
                this['cursor'+property].value = Math.min(Math.max(parseInt(value), 0), this['cursor'+property].valueMax); 
            }
            this.generatePriceAndFonctionnality()
        },

        handleInputBlur(value, property) { //Property = User or Network
            if (isNaN(parseFloat(value)) || !isFinite(value)) {
                this['cursor'+property].value = ''
                this['cursor'+property].value = this['cursor'+property].valueMax/2;
            }
            this.generatePriceAndFonctionnality()
        },
        
        handleValueQuestionChange(value,id) {
            const question = this.questions.find((q) => q.id == id);
            if (question){

                switch (question.typeResponse) {
                    case 'switch':
                        if (typeof value === 'boolean') {
                            question.value = value;
                        }
                        break;

                    case 'inputNumber':

                        if (!isNaN(parseFloat(value)) && isFinite(value)) {
                            question.value = '';
                            question.value = Math.min(Math.max(parseInt(value), question.minValue), question.maxValue);
                        } else {
                            question.value = '';
                            question.value = value.slice(0, -1);
                        }
                        break;
                }
                this.generatePriceAndFonctionnality()
            } 
            
        },

        cancelSubscriptionModal() {
            //EventBus.$emit("showModal", this.$t('dash.modal.are_you_sur'), this.$t('dash.plan.cancel_subscription_modal'), this.$t('dash.confirm'), this.$t('dash.button.cancel'),  "handlerCancelSubscription");
        },
        
        cancelSubscription() {
            console.log('cancelSubscription1')
            this.loaders.subscription = true
            this.subscribePlan({ type: 0 }).then(res => {
                if (res) {
                    let user = JSON.parse('{"_premium":{"active":false,"trial":"1970-01-01T00:00:00.000Z"},"id":"a0d6c9c7-a2f7-4973-b975-3b8a8274d93b","account":"526ce51c-664b-460c-ac4e-096ef9769601","firstname":"Clement","lastname":"Guilloux","phone":"0621721156","avatar":"https://s3-eu-west-1.amazonaws.com/saas.mashup/uploads/0iel1dsi7pco-0k0oebpidbwd-facebook-108113978300524.png","email":"clement@mashup-web.com","admin":true,"created_at":"2024-01-12T09:24:40.000Z","updated_at":"2024-02-07T11:58:08.000Z","confirmed_at":"2024-01-12T09:25:33.000Z","logged_at":"2024-02-07T11:57:51.000Z","partner":false,"pages_access":[],"role":[],"notifications":null}');
                    user._premium.plan = 0
                    localStorage.setItem('user', JSON.stringify(user))
                    this.user = user;
                    this.reset()
                    this.generatePriceAndFonctionnality()
                }
                this.loaders.subscription = false
            })
        },

        startSubscription() {

            document.location.href="https://saas.mashup-web.com/#/dash/plan"; 
            // let user = JSON.parse('{"_premium":{"active":false,"trial":"1970-01-01T00:00:00.000Z"},"id":"a0d6c9c7-a2f7-4973-b975-3b8a8274d93b","account":"526ce51c-664b-460c-ac4e-096ef9769601","firstname":"Clement","lastname":"Guilloux","phone":"0621721156","avatar":"https://s3-eu-west-1.amazonaws.com/saas.mashup/uploads/0iel1dsi7pco-0k0oebpidbwd-facebook-108113978300524.png","email":"clement@mashup-web.com","admin":true,"created_at":"2024-01-12T09:24:40.000Z","updated_at":"2024-02-07T11:58:08.000Z","confirmed_at":"2024-01-12T09:25:33.000Z","logged_at":"2024-02-07T11:57:51.000Z","partner":false,"pages_access":[],"role":[],"notifications":null}');
            // console.log( user)
            // if (this.isCurrentPlan) {   // meme plan mais et meme option
            //     console.log("1")
            //     return
            // }
            // if (user._premium.active && user._premium.plan > 0 && (Object.keys(this.plan).findIndex((k) => k == this.planSelect) == user._premium.plan - 1)) {   // meme plan mais pas meme option

            //     let options_plan = user._premium.options_plan && user._premium.options_plan.length ? user._premium.options_plan : [];
            //     const hasConciergerie = options_plan.includes("conciergerie") || this.plan[this.currentPlan].option.conciergerie == 0
            //     const hasReporting = options_plan.includes("reporting") || this.plan[this.currentPlan].option.reporting == 0;

            //     if (hasConciergerie && !this.optionsChoice.includes("conciergerie") || (hasReporting && !this.optionsChoice.includes("reporting"))) {
            //         //EventBus.$emit("showModal", "Information", "Il n'est pas possible de rétrograder votre abonnement car celui-ci est actuellement actif.", "Fermer");
            //         return
            //     }
                
                
            // }
            // if (user._premium.active && user._premium.plan > 0 && (Object.keys(this.plan).findIndex((k) => k == this.planSelect)  < user._premium.plan - 1)) {   //plan inferieur
            //     //EventBus.$emit("showModal", "Information", "Il n'est pas possible de rétrograder votre abonnement car celui-ci est actuellement actif.", "Fermer");
            //     return
            // }

            // if (user._premium.active && user._premium.plan > 0 && user._premium.is_annual && this.frequencyPlan == 'month') { 
            //     //EventBus.$emit("showModal", "Information", "Il n'est pas envisageable de selectionner un abonnement mensuel tant qu'un abonnement annuel est en cours.", "Fermer");      //frequence abonnement inferieur
            //     return
            // }
    
            // if (!this.hasRegisteredCard) {  //cart en valid
            //     //EventBus.$emit("showModal", "Information", `Votre mode de paiement est invalide`, "Modifier", "Fermer", "handlerEditPaymentMethod");
            //     return
            // }
            // console.log("5",user._premium.active)
            // if (user._premium.active && user._premium.plan > 0) {   // est avnateux pour mw alors accepter et rembourser les x mois non utliser meme principe pour mensuel
            //     //EventBus.$emit("showModal", "Information", `Veuillez contacter le support afin de mettre à niveau votre abonnement.`, "Contacter", "Annuler", "handlerContactStaff");
            //     return
            //     //   let trial = new Date(user._premium.trial)
            //     //   const currentDate = new Date()
                
            //     //   const priceSubscription = this.getPricePlan(user);
            //     //   let priceRefunded = 0;

            //     //   const daysInMonth = (year, month) => new Date(year, month, 0).getDate();
            //     //   const daysInYear = (year) => ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;

            //     //   if (user._premium.is_annual) {
            //     //       trial.getMonth() > currentDate.getMonth() || (trial.getMonth() == currentDate.getMonth() && trial.getDate() > currentDate.getDate()) ? trial.setFullYear(currentDate.getFullYear() - 1) : trial.setFullYear(currentDate.getFullYear());  // mettre la date du dernier prelevement
            //     //       const remainingDays = parseInt(daysInYear(trial.getFullYear()) - (currentDate.getTime()-trial.getTime()) / (24 * 60 * 60 * 1000)); 
            //     //       priceRefunded = priceSubscription/daysInYear(trial.getFullYear())*remainingDays
            //     //   }else{
            //     //       trial.setFullYear(currentDate.getFullYear()) 
                    
            //     //       if (trial.getDate() > currentDate.getDate()) {
            //     //           if (currentDate.getMonth() == 0) {
            //     //               trial.setMonth(11) 
            //     //               trial.setFullYear(currentDate.getFullYear()-1) 
            //     //           }else{
            //     //               trial.setMonth(currentDate.getMonth() - 1) 
            //     //           }
            //     //       }else{
            //     //           trial.setMonth(currentDate.getMonth());
            //     //       }
                    
            //     //       let remainingDays = 0
            //     //       const totalIntervalDay = daysInMonth(trial.getFullYear(),trial.getMonth())
            //     //       if (trial.getDate() >= currentDate.getDate()) {
            //     //           remainingDays = trial.getDate() - currentDate.getDate()
            //     //       }else {
            //     //           remainingDays =  totalIntervalDay - (currentDate.getDate() - trial.getDate())
            //     //       }
            //     //       priceRefunded = priceSubscription/totalIntervalDay*remainingDays
            //     //       console.log(totalIntervalDay,remainingDays,priceSubscription)
            //     //   }

            //     //EventBus.$emit("showModal", "Information", `Vous êtes sur le point de mettre à niveau votre abonnement, un remboursement de ${priceRefunded.toFixed(2)}€ sera appliqué pour tenir compte de la période restante sur votre abonnement actuel.`, "Mise a niveau", "Annuler", "handlerSubscribeFinish");
                
            // }else{
            //     this.handlerSubscribeFinish()
            // }
        },

        async handlerSubscribeFinish() {
            this.loaders.subscription = true
            const res = await this.subscribePlan({ type: Object.keys(this.plan).findIndex((k) => k == this.planSelect)+1, is_annual: this.frequencyPlan == 'year', options: this.optionsChoice.filter((opt) => this.plan[this.planSelect].option[opt] && this.plan[this.planSelect].option[opt] > 0)})
            const { client_secret } = res.body
            let { success } = res.body
            this.loaders.subscription = false
            if (client_secret && success) {
                // const stripe = Stripe(VUE_APP_STRIPE_KEY) // eslint-disable-line
                // const { paymentIntent } = await stripe.confirmCardPayment(client_secret)
                // if (paymentIntent.status !== 'succeeded') {
                //     console.error(
                //     'Confirmation of payment returned with unexpected status:',
                //     paymentIntent
                //     )
                //     EventBus.$emit("showModal", "Information", "Echec lors de la confirmation de votre methode de paiement", "Fermer");
                //     return
                // }
                //EventBus.$emit("showModal", "Information", `Votre mode de paiement est invalide, veuillez l'actualiser`, "Modifier", "Fermer", "handlerEditPaymentMethod");
                return

            }

            if (success) {
                //EventBus.$emit("showModal", "Information", "Votre nouveau abonnement est desormais actif !", "Fermer");
                let user = JSON.parse('{"_premium":{"active":false,"trial":"1970-01-01T00:00:00.000Z"},"id":"a0d6c9c7-a2f7-4973-b975-3b8a8274d93b","account":"526ce51c-664b-460c-ac4e-096ef9769601","firstname":"Clement","lastname":"Guilloux","phone":"0621721156","avatar":"https://s3-eu-west-1.amazonaws.com/saas.mashup/uploads/0iel1dsi7pco-0k0oebpidbwd-facebook-108113978300524.png","email":"clement@mashup-web.com","admin":true,"created_at":"2024-01-12T09:24:40.000Z","updated_at":"2024-02-07T11:58:08.000Z","confirmed_at":"2024-01-12T09:25:33.000Z","logged_at":"2024-02-07T11:57:51.000Z","partner":false,"pages_access":[],"role":[],"notifications":null}');
                user._premium.plan = Object.keys(this.plan).findIndex((k) => k == this.planSelect)+1;
                user._premium.options_plan = this.optionsChoice;
                user._premium.active = true;
                user._premium.is_annual = this.frequencyPlan == 'year';
                localStorage.setItem('user', JSON.stringify(user))
                this.initPlan(user)
                this.generatePriceAndFonctionnality()
                this.user = user;
            }else{
                //EventBus.$emit("showModal", "Information", "Echec lors de l'activation de l'abonnement, veuillez contacter le support.", "Fermer");
            }

        },

        handlerEditPaymentMethod() {
            this.$router.push('/dash/settings/billing');
        },

        beContacted() {
            window.$crisp.push(["do", "chat:open"]);
            let data = this.getInfoPlanSelectForCrisp();
            window.$crisp.push(["do", "message:send", ["text", (this.$t('dash.plan.message_contact_me.main')+ " " + this.$t(data.namePlan)) + (data.options.length > 0 ? (" "+ this.$t('dash.plan.message_contact_me.option') +" : " + data.options.map(option => this.$t(option)).join(', ')) : "")]]);
            window.$crisp.push(["do", "message:show", ["text", this.$t('dash.plan.message_contact_me.response').replace('%s',this.userProp && this.userProp.firstname ? this.userProp.firstname : '')]]);

            
        }

  },

  beforeDestroy () {
      //EventBus.$off('handlerCancelSubscription', this.cancelSubscription);
      //EventBus.$off('handlerEditPaymentMethod', this.handlerEditPaymentMethod);
      //EventBus.$off('handlerSubscribeFinish', this.handlerSubscribeFinish);
      //EventBus.$off('handlerContactStaff', this.beContacted);
      
  },
};
</script>

<style lang="scss" scoped>

</style>