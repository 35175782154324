<template>
  <div id="app">
    <Plan/>
  </div>
</template>

<script>
import Plan from './components/Plan.vue'

export default {
  name: 'App',
  components: {
    Plan
  }
}
</script>

<style src="./assets/scss/style.scss" lang="scss"></style>
